<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
    {{ content }}
    <div style="margin: 10px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
      @for (item of items; track $index) {
        <div style="margin: 10px;">
          <button mat-raised-button (click)="onButtonClick(item)">{{ item }}</button>          
        </div>
      }
    </div>
</div>

@if (!isBtnDisabled) {
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close (click)="onClickNo()">{{ btnNo }}</button>
        <!-- <button mat-button  [mat-dialog-close]="true" (click)="onClickYes()">{{ btnYes }}</button> -->
    </div>
}