<cdk-accordion [style.width]="'100%'">
    <cdk-accordion-item #accordionItem="cdkAccordionItem" role="button" tabindex="0" role="button">
        <div  (contextmenu)="clickRight($event)" (click)="clickLeft()">
            <div [style.margin-left]="data.depth! * 40+'px'" class="accordion-item-header">
                <button mat-icon-button (click)="toggle($event)" [disabled]="checkLeaf()">
                    @if (!checkLeaf()) {
                        <mat-icon>{{isAccordionOpened ? 'expand_more' : 'chevron_right'}}</mat-icon>
                    }
                </button>
                <button mat-menu-item>
                    <mat-icon [ngClass]="{'checked': isChecked, 'base' : !isChecked, 'clicked' : isClicked}">{{isClicked ? 'folder_open': 'folder'}}</mat-icon>
                    <span [ngClass]="{'checked': isChecked, 'base' : !isChecked, 'clicked' : isClicked}" class="text-ellipsis">{{this.data.name}}</span>
                    @if (treeService.menuClass.menuData.length > 0) {
                        <app-menu #menu [menuData]="treeService.menuClass.menuData" [neededData]="this"></app-menu>
                    }
                </button>
                
            </div>
        </div>


        <div cdkDropList (cdkDropListDropped)="dragdrop($event)" role="region"
            [style.display]="isAccordionOpened ? '' : 'none'" class="example-list" >
            @if (!checkLeaf()) {
                @for (item of data.children; track $index) {
                    <app-treerow cdkDrag [id]="item" class="example-box" [cdkDragDisabled]="treeService.dragDisabled"></app-treerow>
                }
            }
        </div>
    </cdk-accordion-item>
</cdk-accordion>