<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
    <mat-form-field>
        <mat-label>{{data.label}}</mat-label>
        <input matInput [placeholder]="data.placeholder" [(ngModel)]="inputData">
    </mat-form-field>
</div>
@if(isInputEmpty){
    <div>{{data.message}}</div>
}
<div mat-dialog-actions>
    <button mat-button (click)="onYesClick()">{{data.btnYes}}</button>
    <button mat-button (click)="onNoClick()">{{data.btnNo}}</button>
</div>