import { CdkDrag, CdkDropList, CdkDropListGroup, DragDropModule } from '@angular/cdk/drag-drop';
import { CdkListbox, CdkOption } from '@angular/cdk/listbox';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TreeviewModule } from '../components/treeview/treeview.module';
import { SwiperComponent } from './components/atoms/swiper/swiper.component';
import { TextComponent } from './components/atoms/text/text.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import { NavigationComponent } from './components/layouts/navigation/navigation.component';
import { AnswerBalloonHTMLComponent } from './components/molecules/answer-balloon-html/answer-balloon-html.component';
import { AnswerBalloonComponent } from './components/molecules/answer-balloon/answer-balloon.component';
import { ConfirmDialogComponent } from './components/molecules/confirm-dialog/confirm-dialog.component';
import { IconButtonComponent } from './components/molecules/icon-button/icon-button.component';
import { InputDialogComponent } from './components/molecules/input-dialog/input-dialog.component';
import { MrcDialogComponent } from './components/molecules/mrc-dialog/mrc-dialog.component';
import { QuestionBalloonComponent } from './components/molecules/question-balloon/question-balloon.component';
import { SpeakIntroComponent } from './components/molecules/speak-intro/speak-intro.component';
import { SplitterDirective } from './directives/splitter.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { CustomFormComponent } from './components/atoms/custom-form/custom-form.component';
import { TodayFormatPipe } from './pipes/today-date.pipe';
import { SelectButtonDialogComponent } from './components/molecules/select-button-dialog/select-button-dialog.component';
import { BottomSheetComponent } from './components/molecules/bottom-sheet/bottom-sheet.component';
import { CardDialogComponent } from './components/molecules/card-dialog/card-dialog.component';
import {MarkdownPipe} from "./pipes/markdown.pipe";
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TrimPipe } from './pipes/trim.pipe';

@NgModule({
  declarations: [
    HeaderComponent,
    QuestionBalloonComponent,
    AnswerBalloonComponent,
    AnswerBalloonHTMLComponent,
    NavigationComponent,
    InputDialogComponent,
    SplitterDirective,
    IconButtonComponent,
    ConfirmDialogComponent,
    SafeHtmlPipe,
    TodayFormatPipe,
    TrimPipe,
    SwiperComponent,
    TextComponent,
    MrcDialogComponent,
    SpeakIntroComponent,
    CustomFormComponent,
    SelectButtonDialogComponent,
    BottomSheetComponent,
    CardDialogComponent,
  ],
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    DragDropModule,
    CdkDropList,
    CdkDrag,
    CdkOption,
    CdkListbox,
    CdkDropListGroup,
    MatToolbarModule,
    MatTooltipModule,
    MatRippleModule,
    ReactiveFormsModule,
    FormsModule, MatFormFieldModule, MatSelectModule, MatInputModule,
    MatFormFieldModule,
    MatChipsModule,
    MatBottomSheetModule,
    MatMenuModule,
    MatDialogModule,
    MatExpansionModule,
    MatCardModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatAutocompleteModule,
    TreeviewModule,
    MatButtonToggleModule,
    MatListModule, MarkdownPipe,
    MatRadioModule,
    MatDatepickerModule
  ],
  exports: [
    CommonModule,
    HeaderComponent,
    MatButtonModule,
    QuestionBalloonComponent,
    AnswerBalloonComponent,
    AnswerBalloonHTMLComponent,
    NavigationComponent,
    ConfirmDialogComponent,
    IconButtonComponent,
    SwiperComponent,
    TextComponent,
    CardDialogComponent,
    MatRippleModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule, MatFormFieldModule, MatSelectModule, MatInputModule,
    SplitterDirective,
    SafeHtmlPipe,
    TodayFormatPipe,
    TrimPipe,
    CdkOption,
    CdkListbox,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatChipsModule,
    MatBottomSheetModule,
    MatMenuModule,
    MatExpansionModule,
    MatCardModule,
    MatSidenavModule,
    DragDropModule,
    MatDividerModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatAutocompleteModule,
    TreeviewModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatListModule,
    MatRadioModule,
    MatDatepickerModule,
    MatToolbarModule
  ],
  providers: [
    DatePipe
  ]
})
export class SharedModule { }
