<div [matMenuTriggerFor]="menu"></div> <!--이게 진짜 필요한가?-->

<mat-menu #menu="matMenu">
    @for (item of menuData; track item) {
        <button mat-menu-item (click)="item?.itemFunc(neededData)" [disabled]="item?.checkDisabled(neededData)">
            @if (item.icon){
                <mat-icon>{{item.icon}}</mat-icon>
            }
            
            <span>{{item.itemName}}</span>
        </button>
        <!--메뉴 클릭하면 이벤트를 여기서 호출하지 말고 바깥의 함수를 실행시키면 문제 해결!-->
    }
</mat-menu>