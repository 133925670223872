import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

export interface IBottomSheet {
  title: string;
  content: string;
  //btnYes: string;
  //btnNo: string;
  isBtnDisabled: boolean;
  isDisableClose: boolean;
}

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrl: './bottom-sheet.component.scss'
})
export class BottomSheetComponent {

  public title: string;
  public content: string;
  public isBtnDisabled: boolean;
  
  constructor(
    private bottomSheetRef: MatBottomSheetRef<IBottomSheet>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: IBottomSheet
  ) {
    this.title = data.title;
    this.content = data.content;
    this.isBtnDisabled = data.isBtnDisabled ?? false;
    this.bottomSheetRef.disableClose = data.isDisableClose ?? false;
  }

  public onClickNo(event: any) {
    this.bottomSheetRef.dismiss({
      close: false
    });
    event.preventDefault();
  }

}
