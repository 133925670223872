<div class="bottom-sheet-top ">
  <h1>{{ title }}</h1>
  @if (!isBtnDisabled) {
    <div>
      <button mat-icon-button matTooltip="취소" (click)="onClickNo($event)">
        <mat-icon>close</mat-icon>
      </button>
      <!-- <button mat-button  [mat-dialog-close]="true" (click)="onClickYes()">{{ btnYes }}</button> -->
    </div>
  }
</div>
<div>
  {{ content }}
</div>