import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'todayFormat'
})
export class TodayFormatPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(date: Date | string): any {
    if (this.isToday(date)) {
      return 'Today';
    } else {
      return this.datePipe.transform(date, 'yyyy.MM.dd'); // Format as desired
    }
  }

  private isToday(date: Date | string): boolean {
    const today = new Date();
    if (typeof date === 'string') {
      date = new Date(date);
    }
    return (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    );
  }
}
