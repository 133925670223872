import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatDrawer, MatDrawerMode } from '@angular/material/sidenav';
import { StoreAbstract } from '../../../../core/classes/store.abstract';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss'
})
export class NavigationComponent extends StoreAbstract implements OnDestroy {

  @ViewChild('drawer', {static: true}) drawer!: MatDrawer;
  
  @Input() triggerType!: string; 
  @Input() position: 'start' | 'end' = 'start';
  @Input() height:number | null = null;
  @Output() drawerOut = new EventEmitter<MatDrawer>();
  
  public mode : MatDrawerMode = 'push';
  public backdrop: boolean = false;
  public drawerWidth: string = '200px';
  public subscribtions: Array<Subscription> = [];

  ngOnInit(): void {
    this.drawer.mode = this.mode;
    this.drawer.position = this.position;

    this.subscribtions.push(this.trigger$.subscribe(d =>{
      if (d.type !== this.triggerType) return;
      this.drawer.toggle();
    }));

    this.subscribtions.push(this.deliver$.subscribe(d =>{
      if (d.type !== this.triggerType) return;
      this.drawer.toggle(d.data);
    }));
    this.drawerOut.emit(this.drawer);
  }

  ngOnDestroy(): void {
      this.subscribtions.forEach(d => d.unsubscribe());
  }

  // toggle(isOpen?: boolean): void {
  //   if(isOpen){
  //     this.drawer.toggle(isOpen);
  //     return;
  //   }

  //   this.drawer.toggle();
  // }

}
