import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface IInputDialog {
  title?: string;
  label: string;
  placeholder: string;
  btnYes: string;
  btnNo: string;
  message?: string;
}
@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrl: './input-dialog.component.scss'
})
export class InputDialogComponent {
  inputData:string = "";
  isInputEmpty: boolean = false;
  constructor(public dialogRef: MatDialogRef<InputDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: IInputDialog){
    console.log('data', data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void{
    if(!this.inputData.trim()){
      this.isInputEmpty = true;
    }else{
      this.dialogRef.close(this.inputData);
    }
    
  }
}
