import { Pipe, PipeTransform } from '@angular/core';
import MarkdownIt from 'markdown-it';

@Pipe({standalone: true, name: 'markdown'})
export class MarkdownPipe implements PipeTransform {
  private md: MarkdownIt;

  constructor() {
    this.md = new MarkdownIt();
  }

  transform(value: string): string {
    return this.md.render(value);
  }
}
