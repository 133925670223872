import { Role } from "../interfaces/role.interface";

export class UpdateUserDto{
    public userIdx!: number;
    public email!: string;
    public password!: string;
    public department!: string;
    public phone!: string;

    constructor(){
        this.userIdx = -1;
        this.email = '';
        this.password = '';
        this.department = '';
        this.phone = '';
    }
}